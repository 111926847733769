import { User as fbUser } from "firebase/auth";
import { Timestamp } from 'firebase/firestore'
import { defineStore } from "pinia";
import {
    fbAuthStateListener,
    fbGetUserByRsaId
} from "@/api/firebase";

export interface User {
    user: any;
    userError: any;
}

interface State {
    user: fbUser | null;
    error: null;
    youth: {
        fbId: string,
        startDate: { nanoseconds: number, seconds: number },
        profilePic: string,
        idNumber: string
    };
    fetchingYouth: boolean
}


export const useAuthStore = defineStore("authStore", {
    // convert to a function
    state: (): State => ({
        user: null,
        error: null,
        youth: {
            fbId: '',
            startDate: { nanoseconds: 0, seconds: 0 },
            profilePic: '',
            idNumber: localStorage.getItem('idNumber') || ''
        },
        fetchingYouth: false
    }),
    getters: {
        isLoggedIn: (state) => state.youth.idNumber !== '',
        userError: (state) => state.error,
        youthStartDate: (state) => new Timestamp(
            state.youth.startDate.seconds,
            state.youth.startDate.nanoseconds
        ).toDate()
    },
    actions: {
        initializeAuthListener() {
            return new Promise((resolve) => {
                fbAuthStateListener(async (user: any) => {
                    this.user = user ? user : null;
                    resolve(true);
                });
            });
        },
        async logout() {
            localStorage.removeItem('idNumber')
            this.$reset()
        },
        async getYouthByRsaId(rsaId: string) {
            this.fetchingYouth = true;
            try {
                const data = await fbGetUserByRsaId(rsaId)
                this.youth = { ...this.youth, ...data };
                localStorage.setItem('idNumber', data.idNumber)
                this.fetchingYouth = false;
                this.error = null;
                return true;
            } catch (e: any) {
                this.fetchingYouth = false;
                this.error = e;
                return false;
            }
        },
    },
});
