import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/store'


const authCheck = (to: any, from: any, next: any) => {
  const store = useAuthStore();
  if (store.isLoggedIn) {
    if (to.name === "login") {
      next({ name: "surveys" });
    } else {
      next();
    }
  } else {
    if (to.name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/surveys',
    beforeEnter: authCheck,
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/modules/auth/views/LoginPage.vue"),
    beforeEnter: authCheck,
  }, {
    name: "surveys",
    path: "/surveys",
    component: () => import("@/modules/surveys/views/SurveysPage.vue"),
    beforeEnter: authCheck,
  }, {
    name: "profile",
    path: "/profile",
    component: () => import("@/modules/profile/views/ProfilePage.vue"),
    beforeEnter: authCheck,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
