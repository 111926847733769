import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    collection,
    getDocs,
    query,
    where,
    Timestamp
} from "firebase/firestore";
import {
    getAuth,
    signOut,
    signInWithPhoneNumber,
    onAuthStateChanged,
} from "firebase/auth";


const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export const fbSignIn = async (phoneNumber: string) => {
    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            console.log('sms sent')
        }).catch((error) => {
            console.log('here')
            console.log(error)
        });
};

export const fbSignOut = async () => {
    await signOut(auth);
    return true;
};

export const fbAuthStateListener = (callback: any) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            callback(user);
        } else {
            // User is signed out
            callback(null);
        }
    });
};

export const fbGetUserByRsaId = async (rsaId: string) => {
    const usersCol = collection(db, 'users');
    const q = query(usersCol, where("idNumber", "==", rsaId));
    const querySnapshot = await getDocs(q);
    const data = {
        ...querySnapshot.docs[0].data(),
        fbId: querySnapshot.docs[0].id
    }
    return data as any
}

export const fbGetAllSurveys = async () => {
    const surveysCol = collection(db, 'surveys');
    const snapshot = await getDocs(surveysCol);
    const data = snapshot.docs.map(doc => {
        return { ...doc.data(), fbId: doc.id } as Survey
    });
    return data;
}

export const fbGetCompletedSurveys = async (userFbId: string) => {
    const completedSurveysCol = collection(db, `users/${userFbId}/Complete_surveys`);
    const snapshot = await getDocs(completedSurveysCol)
    return snapshot.docs.map(doc => {
        const data = doc.data()
        const completedDate = new Timestamp(
            data.completed_date_time.seconds,
            data.completed_date_time.nanoseconds,
        ).toDate()
        return {
            ...data,
            completedDate,
            fbId: doc.id
        } as CompletedSurvey
    });
}