import { defineStore } from "pinia";
import { fbGetAllSurveys, fbGetCompletedSurveys } from "@/api/firebase";
import { useAuthStore } from '@/modules/auth/store'

interface State {
    allSurveys: { [fbId: string]: Survey };
    fetchingAllSurveys: boolean,
    completedSurveys: { [fbId: string]: CompletedSurvey };
    fetchingCompletedSurveys: boolean,
    error: null;
}

export const useSurveysStore = defineStore("surveysStore", {
    state: (): State => ({
        allSurveys: {},
        fetchingAllSurveys: false,
        completedSurveys: {},
        fetchingCompletedSurveys: false,
        error: null,
    }),
    getters: {
        surveyError: (state) => state.error,
        pastSurveys: (state) => {
            const authStore = useAuthStore()
            const today = new Date()
            return Object.values(state.allSurveys).filter((survey) => {
                const expirationDate = new Date(authStore.youthStartDate);
                expirationDate.setDate(
                    expirationDate.getDate()
                    + parseInt(survey.startDate)
                    + parseInt(survey.Expires)
                )
                if (today > expirationDate) return survey
            })
        },
        currentSurveys: (state) => {
            const authStore = useAuthStore()
            const today = new Date()
            return Object.values(state.allSurveys).filter((survey) => {
                const startDate = new Date(authStore.youthStartDate);
                startDate.setDate(
                    startDate.getDate()
                    + parseInt(survey.startDate)
                )
                const expirationDate = new Date(authStore.youthStartDate);
                expirationDate.setDate(
                    expirationDate.getDate()
                    + parseInt(survey.startDate)
                    + parseInt(survey.Expires)
                )
                if (startDate <= today && today <= expirationDate) return survey
            })
        },
        futureSurveys: (state) => {
            const authStore = useAuthStore()
            const today = new Date()
            return Object.values(state.allSurveys).filter((survey) => {
                const startDate = new Date(authStore.youthStartDate);
                startDate.setDate(
                    startDate.getDate()
                    + parseInt(survey.startDate)
                )
                if (today < startDate) return survey
            })
        }
    },
    actions: {
        async getAllSurveys() {
            this.fetchingAllSurveys = true;
            try {
                const data = await fbGetAllSurveys();
                data.forEach(survey => {
                    this.allSurveys[survey.fbId] = survey
                })
                this.fetchingAllSurveys = false;
            } catch (e: any) {
                this.error = e;
                this.fetchingAllSurveys = false;
            }
        },
        async getCompletedSurveys(userFbId: string) {
            this.fetchingCompletedSurveys = true;
            try {
                const data = await fbGetCompletedSurveys(userFbId);
                data.forEach(survey => {
                    this.completedSurveys[survey.surveyID] = survey
                })
                this.fetchingCompletedSurveys = false;
            } catch (e: any) {
                this.error = e;
                this.fetchingCompletedSurveys = false;
            }
        },
    },
});
