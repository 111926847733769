
import { useAuthStore } from "@/store";
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonButton,
  IonThumbnail,
  IonRow,
  IonCol,
  IonFooter,
} from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonButton,
    IonThumbnail,
    IonRow,
    IonCol,
    IonFooter,
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const isLoggedIn = computed(() => {
      return authStore.isLoggedIn;
    });

    const doLogout = () => {
      authStore.logout();
      router.push("/login");
    };
    return { isLoggedIn, doLogout };
  },
});
